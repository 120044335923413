<template>
  <FlotoContentLoader :loading="assetLoading">
    <FlotoForm ref="formRef" @submit="handleFormSubmitted">
      <MRow :gutter="0">
        <MCol :size="12">
          <MRow :gutter="0" class="flex-1">
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="name-input"
                    v-model="formValue.displayName"
                    auto-focus
                    :label="$t('name')"
                    :placeholder="$t('name')"
                    rules="required|short_text"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="asset-type-picker"
                    :label="$tc('asset_type')"
                    rules="required|nonzero"
                  >
                    <FlotoAssetTypePicker
                      v-model="formValue.assetTypeId"
                      as-input
                      :available-asset-type="availableAssetType"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="product-picker" :label="$tc('product')">
                    <ProductPicker v-model="formValue.productId" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="managed-by-group-picker"
                    :label="`${$tc('managed_by')} ${$tc('group')}`"
                  >
                    <FlotoTechnicianGroupPicker
                      v-model="formValue.managedByGroupId"
                      mandatory-selection
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="managed-by-picker"
                    :label="$tc('managed_by')"
                  >
                    <FlotoTechnicianPicker
                      v-model="formValue.managedById"
                      mandatory-selection
                      change-technician-on-group-change
                      :group-id="formValue.managedByGroupId"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="department-picker"
                    :label="$tc('department')"
                  >
                    <FlotoDepartmentPicker
                      v-model="formValue.assignedDepartmentId"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="location-picker" :label="$tc('location')">
                    <FlotoLocationPicker v-model="formValue.locationId" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="asset-group-picker"
                    :label="$tc('asset_group')"
                  >
                    <AssetGroupPicker v-model="formValue.assetGroupId" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware && !isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="used-by-picker" :label="$tc('used_by')">
                    <FlotoRequesterPicker
                      v-model="formValue.assignedUserIds"
                      multiple
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="acquition-date-picker"
                    :label="`${$t('acquition')} ${$t('date')}`"
                  >
                    <FlotoDatePicker
                      v-model="formValue.acquitionTime"
                      :show-time="false"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isSoftware && !isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="assignment-date-picker"
                    :label="`${$t('assignment')} ${$t('date')}`"
                  >
                    <FlotoDatePicker
                      v-model="formValue.assignmentTime"
                      :show-time="false"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="status-picker" :label="$tc('status')">
                    <FlotoStatusPicker
                      v-model="formValue.statusId"
                      :allow-clear="false"
                      mandatory
                      :module-name="$constants.ASSET"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="impact-picker" :label="$tc('impact')">
                    <FlotoImpactPicker
                      v-model="formValue.impactId"
                      as-input
                      :allow-clear="false"
                      mandatory
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="!isConsumable" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="barcode-input"
                    v-model="formValue.barCode"
                    :label="$tc('barcode')"
                    :placeholder="$tc('barcode')"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol v-if="enableMsp" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="company-picker" :label="$tc('company')">
                    <CompanyPicker v-model="formValue.companyId" as-input />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem id="tags-picker" :label="$tc('tag', 2)">
                    <FlotoTagsPicker v-model="formValue.tags" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    id="description-input"
                    :label="$tc('description')"
                    rules="description"
                  >
                    <FlotoRichEditor
                      v-model="formValue.description"
                      :affix-toolbar="false"
                      :min-rows="8"
                      :placeholder="$tc('description')"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol>
                  <FormConsumer
                    v-if="customFieldsForAll.length"
                    v-model="formValue.fieldValueDetails"
                    :form-fields="customFieldsForAll"
                    :avoid-default-value="Boolean(formValue.id)"
                    :focus-event-brodcast="false"
                    :with-submit="false"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem :label="$tc('attachment', 2)">
                    <FlotoAttachment v-model="formValue.fileAttachments" />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
          </MRow>
          <div v-if="formValue.assetTypeId && customFields.length">
            <div
              v-for="assetTypeField in selectedAssetTypes.list"
              :key="assetTypeField.id"
            >
              <template
                v-if="(customFieldForAssetType[assetTypeField.id] || []).length"
              >
                <MDivider dashed />
                <div class="mx-2 text-primary md-text-base my-2">
                  {{ assetTypeField.name }}
                </div>
                <FormConsumer
                  v-model="formValue.fieldValueDetailsForAssetType"
                  :form-fields="customFieldForAssetType[assetTypeField.id]"
                  :avoid-default-value="Boolean(formValue.id)"
                  :with-submit="false"
                  :focus-event-brodcast="false"
                />
              </template>
            </div>
          </div>
        </MCol>
      </MRow>
      <MDivider class="mt-0" />
      <template v-slot:submit>
        <span />
      </template>
    </FlotoForm>
  </FlotoContentLoader>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import ProductPicker from '@components/data-picker/product-picker'
import AssetGroupPicker from '@components/data-picker/asset-group-picker'
import { AssetTypeComputed } from '@state/modules/asset-type'
import { findValuePathWithItems } from '@data/recursive'
import { assetTypeModuleMap, assetTypeRest } from '../helpers/asset-type'
import { customFieldsForAllAssetType } from '../helpers/asset-custom-fields'
import CompanyPicker from '@components/data-picker/company-picker'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'

export default {
  name: 'AssetForm',
  components: { FormConsumer, ProductPicker, AssetGroupPicker, CompanyPicker },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    processing: { type: Boolean, deafult: false },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  data() {
    return {
      internalProcessing: false,
      // @TODO set default value manualy for custom fields
      asset: Object.assign({}, this.value),
    }
  },
  computed: {
    ...FormComputed,
    ...AssetTypeComputed,
    ...MspConfigComputed,
    formValue: {
      get() {
        if (this.withSubmit) {
          return this.asset
        }
        return this.value
      },
      set(value) {
        if (this.withSubmit) {
          this.asset = value
        } else {
          this.$emit('change', value)
        }
      },
    },
    customFields() {
      return (
        ((this.assetForm || {}).fields || []).filter(
          (f) =>
            !f.attributes.assetPropertyType && !f.attributes.assetComponentType
        ) || []
      )
    },
    selectedAssetTypes() {
      if (this.formValue.assetTypeId) {
        const collection = this.assetTypes
        const assetTypeId = this.formValue.assetTypeId
        const path = findValuePathWithItems(collection, assetTypeId)
        const rootItem = path[0]
        return {
          list: path,
          ...rootItem,
          assetTypeRest: assetTypeModuleMap[rootItem.systemName],
        }
      }
      return {}
    },
    isSoftware() {
      if (this.selectedAssetTypes.assetTypeRest === assetTypeRest.SOFTWARE) {
        return true
      }
      return false
    },
    isConsumable() {
      if (this.selectedAssetTypes.assetTypeRest === assetTypeRest.CONSUMABLE) {
        return true
      }
      return false
    },
    customFieldsForAll() {
      return customFieldsForAllAssetType(this.customFields)
    },
    customFieldForAssetType() {
      if (!this.formValue.assetTypeId) {
        return {}
      }
      const fullPath = this.selectedAssetTypes.list
      const assetTypeFields = {}
      fullPath.forEach((p) => {
        const c = this.customFields.filter(
          (f) => f.attributes.assetTypeId === String(p.id)
        )
        assetTypeFields[p.id] = c
      })
      return assetTypeFields
    },
    globalProcessing() {
      return this.processing || this.internalProcessing
    },
  },
  watch: {
    'formValue.assetTypeId': function (newValue, preValue) {
      if (newValue !== preValue) {
        this.$nextTick(() => {
          this.formValue = {
            ...this.formValue,
            type: this.selectedAssetTypes.assetTypeRest,
            fieldValueDetailsForAssetType: {},
          }
        })
      }
    },
  },
  methods: {
    handleFormSubmitted() {
      // const data = {
      //   type: this.selectedAssetTypes.assetTypeRest,
      //   ...this.formValue,
      // }
      this.$emit('submit', this.formValue)
    },
    // handleFieldValueChange(c) {
    //   this.formValue = {
    //     ...this.formValue,
    //     fieldValueDetails: {
    //       ...this.formValue.fieldValueDetails,
    //       ...c,
    //     },
    //   }
    // },
    submit() {
      this.$refs.formRef.submit()
    },
  },
}
</script>
