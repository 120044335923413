export const customFieldsForAllAssetType = (fields) => {
  return (fields || []).filter(
    (f) => !f.attributes.assetTypeId || f.attributes.assetTypeId === 'all'
  )
}

export const customFieldForAssetType = (
  fields,
  assetTypeIds = [],
  propertyName,
  componentName
) => {
  let assetTypeFields = []
  assetTypeIds.forEach((id) => {
    const c = fields.filter((f) => f.attributes.assetTypeId === String(id))
    assetTypeFields = assetTypeFields.concat(c)
  })
  if (propertyName) {
    assetTypeFields = assetTypeFields.filter(
      (f) => f.attributes.assetPropertyType === propertyName
    )
  } else if (componentName) {
    assetTypeFields = assetTypeFields.filter(
      (f) => f.attributes.assetComponentType === componentName
    )
  } else {
    assetTypeFields = assetTypeFields.filter(
      (f) => !f.attributes.assetPropertyType && !f.attributes.assetComponentType
    )
  }
  return assetTypeFields
}
