import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'
import {
  transformAssetGroup,
  transformAssetGroupForServer,
} from '@data/asset-group'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// assetgroup

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(buildNameFilterQuery(qualificationFactors.selectedName, 'in'))
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getAssetGroupsApi(filter, limit, offset, additionParams = {}) {
  return api
    .post(
      `/asset/assetgroup/search/byqual`,
      {
        qualDetails:
          filter.name || filter.enabled || filter.selectedName || filter.ids
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAssetGroup),
        total: data.totalCount,
      }
    })
}

export function createAssetGroupApi(data) {
  return api
    .post(`/asset/assetgroup`, transformAssetGroupForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('asset_group'),
      }),
    })
    .then(transformAssetGroup)
}

export function updateAssetGroupApi(data) {
  return api
    .patch(`/asset/assetgroup/${data.id}`, transformAssetGroupForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('asset_group'),
      }),
    })
    .then(transformAssetGroup)
}

export function deleteAssetGroupApi(id) {
  return api.delete(`/asset/assetgroup/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('asset_group'),
    }),
  })
}
