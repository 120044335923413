/**
 * Translate data before sending to server
 * @param {object} data
 * @returns {object}
 */
export function transformRegisterData(data) {
  return {
    userFirstName: data.firstName,
    userLastName: data.lastName,
    userEmail: data.email,
    companyName: data.companyName,
    userPassword: data.password,
    tenantIdentifier: data.subDomain,
    timezone: data.timezone,
  }
}
