import { colors } from '@utils/color'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'

const $tc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export const discoveryTypeOptions = () => [
  { text: 'WMI', key: 'wmi', value: 'wmi' },
  { text: 'SSH', key: 'ssh', value: 'ssh' },
  { text: 'SNMP', key: 'snmp', value: 'snmp' },
  { text: 'SNMP V3', key: 'snmp_v3', value: 'snmp_v3' },
  { text: 'VMWARE', key: 'vmware', value: 'vmware' },
  { text: 'AWS', key: 'aws', value: 'aws' },
  { text: 'AZURE', key: 'azure', value: 'azure' },
]
export const ipRangeTypeOptions = () => [
  {
    text: 'Entire Network',
    key: 'entire_network',
    value: 'entire_network',
  },
  {
    text: 'Specific IP Range',
    key: 'specific_range',
    value: 'specific_range',
  },
  {
    text: 'Specific set of IPs',
    key: 'comma_separated_ips',
    value: 'comma_separated_ips',
  },
]
export const baselineStatusOtions = () => [
  {
    text: 'Draft',
    key: 'draft',
    value: 'draft',
    color: colors['warning-light'],
  },
  {
    text: 'Published',
    key: 'published',
    value: 'published',
    color: colors.success,
  },
]
export const assetMovementTypeOptions = () => [
  {
    text: 'Send to Repair',
    key: 'send_to_repair',
    value: 'send_to_repair',
    color: '#FF0000',
  },
  {
    text: 'Return From Repair',
    key: 'return_from_repair',
    value: 'return_from_repair',
    color: '#3CB371',
  },
  {
    text: 'Asset Transfer',
    key: 'asset_transfer',
    value: 'asset_transfer',
    color: '#3CB371',
  },
]

export const movementStatusOptions = () => [
  {
    text: 'Requested',
    key: 'requested',
    value: 'requested',
    color: colors['neutral-light'],
  },
  {
    text: 'Completed',
    key: 'completed',
    value: 'completed',
    color: colors.success,
  },
  {
    text: 'Cancelled',
    key: 'cancelled',
    value: 'cancelled',
    color: colors.error,
  },
]

export const assetMovementStatusOptions = () => [
  {
    text: 'None',
    key: 'none',
    value: 'none',
  },
  {
    text: 'Out Movement',
    key: 'out_movement',
    value: 'out_movement',
  },
  {
    text: 'In Repair',
    key: 'in_repair',
    value: 'in_repair',
  },
  {
    text: 'In Movement',
    key: 'in_movement',
    value: 'in_movement',
  },
]

export const returnableTypeOptions = () => [
  { id: 'returnable', key: 'returnable', text: 'Returnable' },
  {
    id: 'non_returnable',
    key: 'non_returnable',
    text: 'Non Returnable',
  },
]
export const transferTypeOptions = () => [
  {
    id: 'temporary_transfer',
    key: 'temporary_transfer',
    text: 'Temporary Transfer',
  },
  {
    id: 'permanent_transfer',
    key: 'permanent_transfer',
    text: 'Permanent Transfer',
  },
]
export const repairedTypeOptions = () => [
  { id: __rootT('repaired'), key: 'repaired', text: 'Repaired' },
  {
    id: __rootT('not_repaired'),
    key: 'not_repaired',
    text: 'Not Repaired',
  },
]
export const assetTypeOptions = () => [
  { text: 'Hardware', key: 'asset_hardware' },
  { text: 'Non IT', key: 'asset_non_it' },
]

export const agentOsPlatformOptions = () => [
  { text: 'Windows', key: 'windows' },
  { text: 'Mac', key: 'mac' },
  { text: 'Linux', key: 'unix' },
  { text: 'Linux Ubuntu', key: 'unix_ubuntu' },
  { text: 'Linux Mint', key: 'unix_mint' },
  { text: 'Linux Redhat', key: 'unix_red_hat' },
  { text: 'Linux Opensuse', key: 'unix_open_suse' },
  { text: 'Linux Suse', key: 'unix_suse' },
  { text: 'Linux Centos', key: 'unix_cent_os' },
  { text: 'Linux Fedora', key: 'unix_fedora' },
  { text: 'Linux Solaris', key: 'unix_solaris' },
  { text: 'Linux Debian', key: 'unix_debian' },
  { text: 'Linux Oracle', key: 'unix_oracle_linux' },
]

export const agentBuildOptions = () => ({
  fields: [
    {
      key: generateId(),
      paramName: 'discovery',
      label: `${$tc('discovery')} ${$tc('build')}`,
      supportedAgents: [
        'unix-bit_32',
        'mac-all',
        'unix-bit_64',
        'windows-bit_32',
        'windows-bit_64',
      ],
    },
    {
      key: generateId(),
      paramName: 'vnc',
      label: `${$tc('vnc')} ${$tc('build')}`,
      supportedAgents: [
        'unix-bit_32',
        'unix-bit_64',
        'windows-bit_32',
        'windows-bit_64',
      ],
    },
    {
      key: generateId(),
      paramName: 'rdp',
      label: `${$tc('mesh_rdp')} ${$tc('build')}`,
      supportedAgents: [
        'mac-all',
        'unix-bit_64',
        'windows-bit_32',
        'windows-bit_64',
      ],
    },
    {
      key: generateId(),
      paramName: 'discovery_with_remotely',
      label: `${$tc('discovery')} ${$tc('with')} ${$tc('remotely')} ${$tc(
        'build'
      )}`,
      supportedAgents: ['windows-bit_32', 'windows-bit_64'],
    },
    {
      key: generateId(),
      paramName: 'discovery_with_rdp',
      label: `${$tc('discovery')} ${$tc('with')} ${$tc('mesh_rdp')} ${$tc(
        'build'
      )}`,
      supportedAgents: [
        'mac-all',
        'unix-bit_64',
        'windows-bit_32',
        'windows-bit_64',
      ],
    },
  ],
})

export const usbConfigurationTypeOptions = () => [
  { text: 'Mouse', key: 'mouse', value: 'mouse' },
  { text: 'Keyboard', key: 'key_board', value: 'key_board' },
  { text: 'Disk Drive', key: 'disk_drive', value: 'disk_drive' },
  { text: 'CD Rom', key: 'cd_rom', value: 'cd_rom' },
  { text: 'Floppy Disk', key: 'floppy_disk', value: 'floppy_disk' },
  { text: 'Printer', key: 'printer', value: 'printer' },
  { text: 'Bluetooth', key: 'bluetooth', value: 'bluetooth' },
]

export const usbConfigurationActionOptions = () => [
  { text: 'Block', key: 'block', value: 'block' },
  { text: 'Unblock', key: 'unblock', value: 'unblock' },
]

export const runAsUserTypeOptions = () => [
  { text: 'Current User', key: 'current_user', value: 'current_user' },
  { text: 'Computer', key: 'computer', value: 'computer' },
]

export const usbConfigurationPlatformOptions = () => [
  { text: 'Unix', key: 'unix', value: 'unix' },
  { text: 'Windows', key: 'windows', value: 'windows' },
]
