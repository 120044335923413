import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformVendorCatalog,
  transformVendorCatalogForServer,
} from '@data/vendor-catalog'
import { isPortalLogin, isLoggedIn } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// vendor catalog

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'in',
        qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.manufacturer) {
    quals.push(
      buildRelationalQualificationStructure(
        'manufacturerId',
        'equal',
        qualificationFactors.manufacturer,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getVendorCatalogsApi(
  filter,
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `${isLoggedIn() ? '/' : '/public/'}asset${
        isPortalLogin() ? '/cportal' : ''
      }/vendor/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.enabled ||
          filter.manufacturer ||
          filter.ids ||
          filter.selectedName
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformVendorCatalog),
        total: data.totalCount,
      }
    })
}

export function createVendorCatalogApi(data) {
  return api
    .post(`/asset/vendor`, transformVendorCatalogForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('vendor'),
      }),
    })
    .then(transformVendorCatalog)
}

export function updateVendorCatalogApi(data) {
  return api
    .patch(`/asset/vendor/${data.id}`, transformVendorCatalogForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('vendor'),
      }),
    })
    .then(transformVendorCatalog)
}

export function getVendorCatalogApi(id) {
  return api.get(`/asset/vendor/${id}`).then(transformVendorCatalog)
}

export function deleteVendorCatalogApi(id) {
  return api.delete(`/asset/vendor/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('vendor'),
    }),
  })
}
