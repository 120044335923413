import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.assetType` },
    meta: {
      moduleName,
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${translator('asset')} ${translator('management')}`
      },
    },
    children: [
      {
        path: 'asset-types',
        name: `${routeNamePrefix}.assetType`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/asset-type-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('asset')} ${pluralTranslator('type')}`
          },
        },
      },
      {
        path: 'software-type',
        name: `${routeNamePrefix}.software-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/software-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('software_type')}`
          },
        },
      },
      {
        path: 'snmp-custom-properties',
        name: `${routeNamePrefix}.snmp-custom-properties`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/snmp-custom-properties-list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('snmp_custom_properties')}`
          },
        },
      },
      {
        path: 'manage-baseline',
        name: `${routeNamePrefix}.manage-baseline`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/manage-baseline-list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('baseline', 2)}`
          },
        },
      },
      {
        path: 'manage-baseline/create',
        name: `${routeNamePrefix}.baseline-create`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request" */ './views/manage-baseline-create'
            )
          ),
      },
      {
        path: 'manage-baseline/edit/:id',
        name: `${routeNamePrefix}.baseline-edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/manage-baseline-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'manage-baseline/:id',
        name: `${routeNamePrefix}.baseline-view`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/manage-baseline-view'
            )
          ),
        meta: {},
      },
      {
        path: 'software-category',
        name: `${routeNamePrefix}.software-category`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/software-category-list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('software')} ${pluralTranslator(
              'category'
            )}`
          },
        },
      },
      {
        path: 'product-type',
        name: `${routeNamePrefix}.product-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/product-type-list')
          ),
        meta: {
          breadcrumbKey: 'product_type',
        },
      },
      {
        path: 'barcode',
        name: `${routeNamePrefix}.barcode`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/barcode-list')
          ),
        meta: {
          breadcrumbKey: 'barcode',
        },
      },
      {
        path: 'rdp-configuration',
        name: `${routeNamePrefix}.rdp-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/rdp-configuration-form'
            )
          ),
        meta: {
          breadcrumbKey: 'rdp_configuration',
        },
      },
      {
        path: 'asset-movement',
        name: `${routeNamePrefix}.asset-movement`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/asset-movement/asset-movement-list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('asset_movement', 2)}`
          },
        },
      },
      {
        path: 'asset-movement/assettorepair',
        name: `${routeNamePrefix}.movement-assettorepair`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request" */ './views/asset-movement/asset-to-repair-create'
            )
          ),
      },
      {
        path: 'asset-movement/assetfromrepair',
        name: `${routeNamePrefix}.movement-assetfromrepair`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request" */ './views/asset-movement/asset-from-repair-create'
            )
          ),
      },
      {
        path: 'asset-movement/assettransfer',
        name: `${routeNamePrefix}.movement-assettransfer`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request" */ './views/asset-movement/asset-transfer-create'
            )
          ),
      },
      {
        path: 'asset-movement/:id',
        name: `${routeNamePrefix}.movement-view`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/asset-movement/asset-movement-view'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('asset_movement', 2)}`
          },
        },
      },
      {
        path: 'asset-movement/assettorepair/edit/:id',
        name: `${routeNamePrefix}.movement-sendtorepairedit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/asset-movement/asset-to-repair-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-movement/returnfromrepair/edit/:id',
        name: `${routeNamePrefix}.movement-returnfromrepairedit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/asset-movement/asset-from-repair-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-movement/assettransfer/edit/:id',
        name: `${routeNamePrefix}.movement-assettransferedit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/asset-movement/asset-transfer-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-discovery',
        name: `${routeNamePrefix}.assetDiscovery`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/discovery')
          ),
        meta: {
          breadcrumbKey: 'asset_discovery',
        },
      },
      {
        path: 'asset-group',
        name: `${routeNamePrefix}.asset-group`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/asset-group-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator('asset')} ${pluralTranslator('group')}`
          },
        },
      },
      {
        path: 'product-catalog',
        name: `${routeNamePrefix}.product-catalog`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/product-catalog-list-container'
            )
          ),
        meta: {
          breadcrumbKey: 'product_catalog',
        },
      },
      {
        path: 'vendor-catalog',
        name: `${routeNamePrefix}.vendor-catalog`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/vendor-catalog-list'
            )
          ),
        meta: {
          breadcrumbKey: 'vendor_catalog',
        },
      },
      {
        path: 'manufacturer-catalog',
        name: `${routeNamePrefix}.manufacturer-catalog`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/manufacturer-catalog-list'
            )
          ),
        meta: {
          breadcrumbKey: 'manufacturer_catalog',
        },
      },
      {
        path: 'software-rules',
        name: `${routeNamePrefix}.software-rules`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/asset-software-rules'
            )
          ),
        meta: {
          breadcrumbKey: 'software_rule',
        },
      },
      {
        path: 'asset-relationship-types',
        name: `${routeNamePrefix}.asset-relationship-types`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/asset-relationship-types'
            )
          ),
        meta: {
          breadcrumbKey: 'relationship_type',
        },
      },
      {
        path: 'ip-range-location',
        name: `${routeNamePrefix}.ipRangeLocation`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/ip-range-location'
            )
          ),
        meta: {
          breadcrumbKey: 'ip_range_location',
        },
      },
      {
        path: 'wake-on-lan',
        name: `${routeNamePrefix}.wake-on-lan`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "asset-management" */ './views/wake-on-lan-scheduler-list'
            )
          ),
        meta: {
          breadcrumbKey: 'wake_on_lan_schedules',
        },
      },
      {
        path: 'wake-on-lan-scheduler/create',
        name: `${routeNamePrefix}.wol-scheduler-create`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/wake-on-lan-scheduler-create'
            )
          ),
        meta: {},
      },
      {
        path: 'wake-on-lan-scheduler/edit/:id',
        name: `${routeNamePrefix}.wol-scheduler-edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/wake-on-lan-scheduler-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-configuration',
        name: `${routeNamePrefix}.asset-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/asset-configuration/asset-configuration'
            )
          ),
        meta: {
          breadcrumbKey: 'asset_configuration',
        },
      },
      {
        path: 'create-asset-configuration',
        name: `${routeNamePrefix}.create-asset-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/asset-configuration/create'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-configuration-list',
        name: `${routeNamePrefix}.asset-configuration-list`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/asset-configuration/list'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-configuration-detail',
        name: `${routeNamePrefix}.asset-configuration-detail`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "users" */ './views/asset-configuration/detail'
            )
          ),
        meta: {},
      },
      {
        path: 'asset-configuration/:id',
        name: `${routeNamePrefix}.asset-configuration-view`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/asset-configuration/view'
            )
          ),
        meta: {},
      },
      {
        path: 'edit-asset-configuration/:id',
        name: `${routeNamePrefix}.edit-asset-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "purchase" */ './views/asset-configuration/edit'
            )
          ),
        meta: {},
      },
      {
        path: 'deployment-policy',
        name: `${routeNamePrefix}.deployment-policy`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management/views/deployment-policy-configuration" */ '../patch-management/views/deployment-policy-configuration/list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('deployment_policy')}`
          },
        },
      },
      {
        path: 'geolocation',
        name: `${routeNamePrefix}.geolocation`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/geolocation/geolocation'
            )
          ),
        meta: {
          breadcrumbKey: 'geolocation',
        },
      },
    ],
  },
]
