import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformManufacturerCatalog,
  transformManufacturerCatalogForServer,
} from '@data/manufacturer-catalog'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// manufacturer catalog

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'in',
        qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.id) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'equal',
        qualificationFactors.id,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getManufacturerCatalogsApi(
  filter,
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `/asset/manufacturer/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.enabled ||
          filter.selectedName ||
          filter.id ||
          filter.ids
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformManufacturerCatalog),
        total: data.totalCount,
      }
    })
}

export function createManufacturerCatalogApi(data) {
  return api
    .post(`/asset/manufacturer`, transformManufacturerCatalogForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('manufacturer'),
      }),
    })
    .then(transformManufacturerCatalog)
}

export function updateManufacturerCatalogApi(data) {
  return api
    .patch(
      `/asset/manufacturer/${data.id}`,
      transformManufacturerCatalogForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('manufacturer'),
        }),
      }
    )
    .then(transformManufacturerCatalog)
}

export function deleteManufacturerCatalogApi(id) {
  return api.delete(`/asset/manufacturer/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('manufacturer'),
    }),
  })
}
