<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { AssetTypeMethods } from '@state/modules/asset-type'
import { CategoryMethods } from '@state/modules/category'
import { ProductTypeMethods } from '@state/modules/product-type'
export default {
  name: 'AssetManagementModule',
  page() {
    return {
      title: `${this.$tc('asset')} ${this.$tc('management')}`,
    }
  },
  beforeRouteEnter(to, from, next) {
    const allowedForms = [Constants.ASSET, Constants.PRODUCT]
    if (
      to.params.moduleName &&
      allowedForms.indexOf(to.params.moduleName) === -1
    ) {
      return next({ name: '404' })
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (from.name.indexOf('software-category') >= 0) {
      this[`${Constants.ASSET_SOFTWARE}CategoriesFetch`]()
    }
    if (from.name.indexOf('assetType') >= 0) {
      this.fetchAssetType()
    }
    if (from.name.indexOf('product-type') >= 0) {
      this.fetchProductTypes()
    }
    // add any module level clean up logic here
    next()
  },
  methods: {
    ...AssetTypeMethods,
    ...CategoryMethods,
    ...ProductTypeMethods,
  },
}
</script>
