import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformProductCatalog,
  transformProductCatalogForServer,
} from '@data/product-catalog'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// product catalog

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids || [],
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'in',
        qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.manufacturer) {
    quals.push(
      buildRelationalQualificationStructure(
        'manufacturerId',
        'equal',
        qualificationFactors.manufacturer,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getProductCatalogsApi(
  filter,
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `/asset/product/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.enabled ||
          filter.manufacturer ||
          filter.selectedName ||
          filter.ids
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformProductCatalog),
        total: data.totalCount,
      }
    })
}

export function createProductCatalogApi(data) {
  return api
    .post(`/asset/product`, transformProductCatalogForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('product'),
      }),
    })
    .then(transformProductCatalog)
}

export function updateProductCatalogApi(data) {
  return api
    .patch(
      `/asset/product/${data.id}`,
      transformProductCatalogForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('product'),
        }),
      }
    )
    .then(transformProductCatalog)
}

export function deleteProductCatalogApi(id) {
  return api.delete(`/asset/product/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('product'),
    }),
  })
}
