import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator, pluralTranslator) {
        return `${translator(
          route.params.moduleName || route.query.type
        )} ${translator('management')}`
      },
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "admin" */ './views/list')),
        meta: { breadcrumbKey: 'custom_rules' },
      },
    ],
  },
]
